import React from 'react'
// import { Link } from 'gatsby'

// import logo from '../assets/images/logo.svg';

const Footer = (props) => (
    <footer id="footer">
        {/**
        <section>
            <h2>Aliquam sed mauris</h2>
            <p>Sed lorem ipsum dolor sit amet et nullam consequat feugiat consequat magna adipiscing tempus etiam dolore veroeros. eget dapibus mauris. Cras aliquet, nisl ut viverra sollicitudin, ligula erat egestas velit, vitae tincidunt odio.</p>
        </section>

        <section>
            <h2>Contact Information</h2>
            <dl className="alt">
                <dt>Address</dt>
                <dd>Phoenix &bull; Arizona &bull; USA</dd>
                <dt>Email</dt>
                <dd><a href="#">init@northatlantic.tech</a></dd>
            </dl>

        <section>
            <ul className="icons">
                <li><a href="#" className="icon fa-twitter alt"><span className="label">Twitter</span></a></li>
                <li><a href="#" className="icon fa-github alt"><span className="label">GitHub</span></a></li>
            </ul>
        </section>
        */}
        <p className="copyright">&copy; 2019 North Atlantic Tech</p>
    </footer>
)

export default Footer
